import React from 'react';
import moment from 'moment';

const ChangeCarrotLogo = props => {
  const { image, imageXmas } = props;

  const currentDate = moment();
  const december1st = moment({ date: 1, month: 11 });
  const december31st = moment({ date: 31, month: 11 });

  if (currentDate.isSameOrAfter(december1st) && currentDate.isSameOrBefore(december31st)) {
    return (
      <>
        {imageXmas && (
          <>
            {imageXmas}
          </>
        )}
      </>
    );
  }

  return (
    <>
      {image && (
        <>
          {image}
        </>
      )}
    </>
  );
};

export default ChangeCarrotLogo;
