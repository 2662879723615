import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from './Button';
import Page from './Page';
import Input from './Input';

import * as Helpers from '../helpers';

import Vector from '../assets/images/vector.svg';
import group from '../assets/images/teller-icon.png';

class Keypad extends Component {
  state = {
    number: '0',
  };

  options = [
    { label: '3 months', value: '3' },
    { label: '6 months', value: '6' },
  ];

  componentDidMount() {
    const { refresh } = this.props;
    if (refresh) {
      this.interval = setInterval(() => {
        refresh();
      }, 100000);

      window.onfocus = () => {
        const { focused } = this.state;
        if (focused === false) {
          refresh();
          this.setState({ focused: true });
        }
      };

      window.onblur = () => {
        this.setState({ focused: false });
      };
    }
  }

  componentDidUpdate = () => {
    const { value, setFirstLoad } = this.props;
    if (value && value > 0) {
      this.setNum(value);
      setFirstLoad();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  toggleFraction = () => {
    const { number } = this.state;
    const period = /\.+/.test(number) ? '' : '.';
    this.setNum(`${number}${period}`);
  }

  setNum = newAmount => {
    const { setAmount } = this.props;
    this.setState({ number: newAmount }, () => {
      setAmount(newAmount);
    });
  };

  addNumber = paddingNumber => () => {
    const { amount } = this.props;
    const { number } = this.state;
    const num = number === '0' ? '' : number;
    const newAmount = `${num}${paddingNumber}`;
    // If a full stop exists then every character typed is for the tenth unit first
    // then the hundreth unit, and after that the tenth again, then the hundredth
    const decimalPlaces = newAmount.split('.')[1]?.length;
    if (parseFloat(newAmount) <= parseFloat(amount) && (!decimalPlaces || decimalPlaces <= 2)) {
      this.setNum(newAmount);
    } else if (decimalPlaces > 2) {
      Helpers.notification.error('You exceeded the max of 2 decimal places');
    } else {
      Helpers.notification.error(`Maximum allowed is $${Helpers.formatMoney(amount)}`);
    }
  };

  removeNumber = () => {
    const { number } = this.state;
    const num = (`${number}`);
    let newAmount = num.substring(0, num.length - 1);
    if (newAmount.substring(-1) === '.') {
      newAmount = newAmount.substring(0, newAmount.length - 1);
      this.toggleFraction();
    }

    if (newAmount === '') {
      this.setNum('0');
    } else {
      this.setNum(newAmount);
    }
  };

  setMax = () => {
    const { amount } = this.props;
    this.setNum(amount);
  };

  continue = () => {
    const {
      next, minimum, type, amount,
    } = this.props;
    const { number } = this.state;

    if (Number(number) >= Number(minimum)) {
      if (type === 'repay' && (amount - number) !== 0 && (amount - number) < 1) {
        Helpers.notification.error(
          'You cannot have less than $1 left to repay.'
        );
      } else {
        next();
      }
    } else {
      Helpers.notification.error(
        `You need to enter a number equal to or greater than $${minimum} to continue.`
      );
    }
  };

  render() {
    const {
      amount, back, background, heading, buttonText, buttonDisabled,
      appLogo, setTenure, tenure,
    } = this.props;
    const { number } = this.state;
    return (
      <Page
        title="Keypad"
        alignContentMobile="align-center"
        back={back}
        background={background}
        appLogo={appLogo}
        hasHeader
        hasLogo
        hm="50px 0 0 0"
        lm="35px 0 0 0"
        footer={<></>}
      >
        <div id="keypad">
          <div className="teller-image web-display-none mobile-display-block">
            <img src={group} alt="" />
          </div>
          <div className="heading keypad">{heading}</div>
          <div className="keypad-label">Enter Amount</div>
          <div id="keypad-value">
            $
            {number}
          </div>
          <div style={{
            display: 'flex', justifyContent: 'space-between', padding: '0 10px', width: '100%',

          }}
          >
            <div id="keypad-amount" onClick={this.setMax}>
              <p>Max.</p>
              &nbsp;
              $
              {Helpers.formatMoney(amount, 2, true)}
            </div>
            {window.location.pathname === '/offer' && (
            <div
              id="no_placeholder_input"
            >
              <Input
                placeholder="Tenure"
                type="select"
                noPlaceholder
                name="tenure"
                id="tenure"
                onChange={setTenure}
                value={tenure}
                options={this.options}
              />
            </div>
            )}
          </div>

          <div className="keypad-column">
            <div className="keypad-row" onClick={this.addNumber(1)}>1</div>
            <div className="keypad-row" onClick={this.addNumber(2)}>2</div>
            <div className="keypad-row" onClick={this.addNumber(3)}>3</div>
          </div>
          <div className="keypad-column">
            <div className="keypad-row" onClick={this.addNumber(4)}>4</div>
            <div className="keypad-row" onClick={this.addNumber(5)}>5</div>
            <div className="keypad-row" onClick={this.addNumber(6)}>6</div>
          </div>
          <div className="keypad-column">
            <div className="keypad-row" onClick={this.addNumber(7)}>7</div>
            <div className="keypad-row" onClick={this.addNumber(8)}>8</div>
            <div className="keypad-row" onClick={this.addNumber(9)}>9</div>
          </div>
          <div className="keypad-column">
            <div className="keypad-row no-border" onClick={this.toggleFraction}>&bull;</div>
            <div className="keypad-row" onClick={this.addNumber(0)}>0</div>
            <div className="keypad-row no-border" onClick={this.removeNumber}><img src={Vector} alt="delete" /></div>
          </div>

          <div id="keypad-full-button">
            <Button onClick={this.continue} size="large" background="light" disabled={buttonDisabled}>{buttonText || 'CONFIRM'}</Button>
          </div>
        </div>

      </Page>

    );
  }
}

Keypad.props = {
  amount: PropTypes.number.isRequired,
  back: PropTypes.func.isRequired,
  background: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  heading: PropTypes.string.isRequired,
  minimum: PropTypes.number.isRequired,
  next: PropTypes.func.isRequired,
  setAmount: PropTypes.func.isRequired,
  setFirstLoad: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.number,
};

export default Keypad;
