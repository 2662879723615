import React from 'react';
import { PaystackConsumer } from 'react-paystack';
import sha256 from 'sha256';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';

import {
  Keypad, Successful, Page, Loading, Button
} from '../components';

import * as Helpers from '../helpers';

import dollarSign from '../assets/images/dollar-sign.png';
import nairaSign from '../assets/images/naira-sign.png';
import tickCircle from '../assets/images/tick-circle.svg';

class Repayment extends React.Component {
  state = {
    amount: 0,
    firstLoad: true,
    loanID: 0,
    message: '',
    open: false,
    paste: true,
    payStackAmount: 0,
    repayMethod: '',
    show: {
      choice: true,
      keypad: true,
    },
    success: false,
    value: 0,
  };

  componentDidMount() {
    const { history, location, load } = this.props;
    document.body.classList.remove('no-overflow');

    if (
      !location.search
      && (!location.query
        || typeof location.query.loanID === 'undefined'
        || typeof location.query.amount === 'undefined'
        || typeof location.query.value === 'undefined')
    ) {
      Helpers.notification.error(
        'You cannot access the repayment page, to go there click a repayment button.'
      );
      return history.push('/dashboard');
    }

    if (location.search) {
      const amount = location.search.match(/amount=(\d*)/);
      if (amount && amount[1]) {
        this.setState({
          amount: Helpers.math.divide(amount[1], 100),
          loanID: 0,
          value: 0,
        });
      } else {
        return history.push('/dashboard');
      }
      load();
    } else if (location.query) {
      const { loanID, amount, value } = location.query;
      if (loanID !== 0) {
        const { getSingleLoan } = this.props;
        getSingleLoan(loanID, res => {
          const {
            minimum: { interest, amount: totalMinimum },
            principal,
            totalRepaid,
          } = res.data.loans[0];
          this.setState({
            amount: Helpers.math.add(
              Helpers.math.divide(
                Helpers.math.subtract(
                  parseInt(principal, 10),
                  parseInt(totalRepaid, 10)
                ),
                100
              ),
              Helpers.math.divide(parseInt(interest, 10), 100)
            ),
            loanID,
            value: Helpers.math.divide(parseInt(totalMinimum, 10), 100),
          });
        });
      } else {
        this.setState({
          amount,
          loanID,
          value,
        });
      }
    } else {
      return history.push('/dashboard');
    }

    return null;
  }

  componentDidUpdate = () => {
    const { history, user: { blacklist } } = this.props;
    if (blacklist?.signin || blacklist?.repayment) {
      Helpers.notification.error('You are not allowed to use this service.');
      history.push('/dashboard');
    }
  }

  setAmount = payStackAmount => {
    this.setState({ payStackAmount });
  };

  success = () => {
    // const { loanID } = this.state;
    // const {
    //   recordRepayment, load, getLoans, loan: { table: { limit, offset } }, user: { user_id },
    // } = this.props;
    // setTimeout(() => {
    //   recordRepayment(data.reference, loanID, user_id, () => {
    //     setTimeout(() => {
    //       load();
    //       getLoans(limit, offset);
    //     }, 1000);
    //   });
    // }, 10000);

    this.setState(
      {
        message: '',
        success: true,
      },
      this.show(null, 'open', false)
    );
  };

  // success = () => {
  //   this.setState({
  //     message: '',
  //     success: true,
  //   }, this.show(null, 'open', false));
  // }

  getImage = slug => {
    switch (slug) {
    case 'naira-bank':
      return nairaSign;
    case 'auto-dollar-bank':
      return dollarSign;
    case 'manual-dollar-bank':
      return dollarSign;
    default:
      return '';
    }
  };

  goTo = () => {
    const { history } = this.props;
    history.push('/dashboard');
  };

  show = (comp, option, value) => () => {
    const { show } = this.state;
    this.setState({
      [option]: value,
      show: {
        ...show,
        ...Object.keys(show).reduce(
          (prev, cur) => ({ ...prev, [cur]: false }),
          {}
        ),
        [comp]: true,
      },
    });
  };

  setRepayMethod = method => {
    this.setState({ repayMethod: method });
  };

  back = trigger => () => {
    const { history } = this.props;
    history.push('/dashboard', {
      trigger,
    });
  };

  setFirstLoad = () => {
    this.setState({ firstLoad: false });
  };

  onCopy = () => {
    const { paste } = this.state;
    this.setState({ paste });
    Helpers.notification.success('', 'Copied');
  };

  refresh = () => {
    const { load } = this.props;
    load();
  }

  render() {
    const {
      amount,
      open,
      message,
      payStackAmount,
      success,
      show,
      loanID,
      repayMethod,
      firstLoad,
      value,
    } = this.state;
    const {
      user: {
        user_id, email, info, loading,
      },
      history,
    } = this.props;
    const rate = !info.rates ? 0 : info.rates.buy;

    if (loading.some(url => url === '/repayment')) {
      return <Loading size="big" />;
    }
    if (success) {
      return (
        <Successful
          title="Repayment Successful"
          subtitle={
            message
            || `You have successfully repaid $${payStackAmount} of your credit.`
          }
          background="illustration-1"
          history={history}
        />
      );
    }
    if (show.keypad) {
      return (
        <Keypad
          minimum={1}
          back={this.back()}
          background="illustration-1"
          heading="How much do you want to repay?"
          amount={amount}
          next={this.show('choice')}
          setAmount={this.setAmount}
          type="repay"
          buttonText="REPAY"
          buttonDisabled={!payStackAmount > 0}
          value={firstLoad ? value : 0}
          setFirstLoad={this.setFirstLoad}
          refresh={this.refresh}
        />
      );
    }

    return (
      <>
        {show.choice && (
          <Page
            title="Repay"
            alignContentMobile="align-left"
            back={this.show('keypad')}
            background="illustration-1"
            hasHeader
            hasLogo
            hm="20px 0 0 0"
            lm="39.2px 0 0 0"
            footer={<></>}
          >
            <div className="heading disburseOptions">Make a Repayment</div>

            <div className="subheading disburseOptions">
              Select your preferred payment option.
            </div>

            <div className="disburse-options">
              {[
                { name: 'Naira Account', slug: 'naira-bank' },
                { name: 'Dollar Account', slug: 'manual-dollar-bank' },
              ].map((repay, i) => (
                <button
                  key={i}
                  className="item highlight"
                  onClick={() => this.setRepayMethod(i === 0 ? 'naira' : 'dollar')}
                >
                  <img
                    src={this.getImage(repay.slug)}
                    alt=""
                    className="currency-image"
                  />
                  {repay.name}
                  <img className="tick" src={tickCircle} alt="" />
                </button>
              ))}
            </div>

            {repayMethod === 'dollar' && (
              <div className="details-background">
                <div className="details-content">
                  <span className="title">Pay back in Dollar (USD)</span>
                </div>

                <div className="details-content">
                  <span>
                    Use the following details to send ACH or domestic wires (applies to banks within USA) to our Brex Cash account:
                  </span>
                </div>

                <div className="details-content">
                  <p>ABA/Routing Number</p>
                  <span>121145349</span>
                  {' '}
                  &nbsp;
                  <CopyToClipboard text="121145349" onCopy={this.onCopy}>
                    <FaRegCopy style={{ cursor: 'pointer' }} />
                  </CopyToClipboard>
                </div>

                <div className="details-content">
                  <p>Account Number</p>
                  <span>164040951944184</span>
                  {' '}
                  &nbsp;
                  <CopyToClipboard text="164040951944184" onCopy={this.onCopy}>
                    <FaRegCopy style={{ cursor: 'pointer' }} />
                  </CopyToClipboard>
                </div>

                <div className="details-content">
                  <p>Account Type</p>
                  <span>Business Checking</span>
                  {' '}
                  &nbsp;
                  <CopyToClipboard
                    text="Business Checking"
                    onCopy={this.onCopy}
                  >
                    <FaRegCopy style={{ cursor: 'pointer' }} />
                  </CopyToClipboard>
                </div>

                <div className="details-content">
                  <p>Beneficiary name</p>
                  <span>Carrot Innovation LLC</span>
                  {' '}
                  &nbsp;
                  <CopyToClipboard
                    text="Carrot Innovation LLC"
                    onCopy={this.onCopy}
                  >
                    <FaRegCopy style={{ cursor: 'pointer' }} />
                  </CopyToClipboard>
                </div>

                <div className="details-content">
                  <p>Beneficiary address</p>
                  <span>
                    9802 Woodyard Circle, Upper Marlboro, MD 20772, United States
                  </span>
                  {' '}
                  &nbsp;
                  <CopyToClipboard
                    text="9802 Woodyard Circle, Upper Marlboro, MD 20772, United States"
                    onCopy={this.onCopy}
                  >
                    <FaRegCopy style={{ cursor: 'pointer' }} />
                  </CopyToClipboard>
                </div>

                <div className="details-content">
                  <p>Bank Name</p>
                  <span>Column NA - Brex</span>
                  {' '}
                  &nbsp;
                  <CopyToClipboard text="Column NA - Brex" onCopy={this.onCopy}>
                    <FaRegCopy style={{ cursor: 'pointer' }} />
                  </CopyToClipboard>
                </div>

                <div className="details-content">
                  <p>Bank Address</p>
                  <span>
                    1 Letterman Drive Building A, Suite A4-700, San Francisco, CA 94129, United States
                  </span>
                  {' '}
                  &nbsp;
                  <CopyToClipboard
                    text="1 Letterman Drive Building A, Suite A4-700, San Francisco, CA 94129, United States"
                    onCopy={this.onCopy}
                  >
                    <FaRegCopy style={{ cursor: 'pointer' }} />
                  </CopyToClipboard>
                </div>

                {' '}
                &nbsp;

                <CopyToClipboard
                  text="
                  121145349;
                  164040951944184;
                  Business Checking;
                  Carrot Innovation LLC;
                  9802 Woodyard Circle, Upper Marlboro, MD 20772, United States;
                  Column NA - Brex;
                  1 Letterman Drive Building A, Suite A4-700, San Francisco, CA 94129, United States;
                  "
                  onCopy={this.onCopy}
                >
                  <button className="copyButton">COPY ALL</button>
                </CopyToClipboard>
                <br />

                <div className="details-content">
                  <span className="title">
                    International wire instructions
                  </span>
                </div>

                <div className="details-content">
                  <span className="title">
                    Follow the instructions below to send an international wire to our Brex Cash account:
                  </span>
                </div>

                <div className="details-content car-green">
                  <span className="title">
                    Step 1: Enter the following beneficiary bank information:
                  </span>
                </div>

                <div className="details-content">
                  <p>SWIFT/BIC Code</p>
                  <span>CLNOUS66BRX</span>
                  {' '}
                  &nbsp;
                  <CopyToClipboard text="CLNOUS66BRX" onCopy={this.onCopy}>
                    <FaRegCopy style={{ cursor: 'pointer' }} />
                  </CopyToClipboard>
                </div>

                <div className="details-content">
                  <p>Bank Name</p>
                  <span>Column NA - Brex</span>
                  {' '}
                  &nbsp;
                  <CopyToClipboard text="Column NA - Brex" onCopy={this.onCopy}>
                    <FaRegCopy style={{ cursor: 'pointer' }} />
                  </CopyToClipboard>
                </div>

                <div className="details-content">
                  <p>Bank Address</p>
                  <span>1 Letterman Drive, Building A, Suite A4-700, San Francisco, CA, 94129, United States</span>
                  {' '}
                  &nbsp;
                  <CopyToClipboard text="1 Letterman Drive, Building A, Suite A4-700, San Francisco, CA, 94129, United States" onCopy={this.onCopy}>
                    <FaRegCopy style={{ cursor: 'pointer' }} />
                  </CopyToClipboard>
                </div>

                <div className="details-content car-green">
                  <span className="title">
                    Step 2: Enter the following intermediary bank information:
                  </span>
                </div>

                <div className="details-content">
                  <p>SWIFT/BIC code</p>
                  <span>CHASUS33</span>
                  {' '}
                  &nbsp;
                  <CopyToClipboard text="CHASUS33" onCopy={this.onCopy}>
                    <FaRegCopy style={{ cursor: 'pointer' }} />
                  </CopyToClipboard>
                </div>

                <div className="details-content">
                  <p>Bank Name</p>
                  <span>JPMorgan Chase Bank N.A.</span>
                  {' '}
                  &nbsp;
                  <CopyToClipboard
                    text="JPMorgan Chase Bank N.A."
                    onCopy={this.onCopy}
                  >
                    <FaRegCopy style={{ cursor: 'pointer' }} />
                  </CopyToClipboard>
                </div>

                <div className="details-content">
                  <p>Bank Address</p>
                  <span>383 Madison Avenue, New York, NY 10179, United States</span>
                  {' '}
                  &nbsp;
                  <CopyToClipboard text="383 Madison Avenue, New York, NY 10179, United States" onCopy={this.onCopy}>
                    <FaRegCopy style={{ cursor: 'pointer' }} />
                  </CopyToClipboard>
                </div>

                <div className="details-content car-green">
                  <span className="title">
                    Step 3: Enter the following beneficiary information:
                  </span>
                </div>

                <div className="details-content">
                  <p>Beneficiary Name</p>
                  <span>
                    Carrot Innovation LLC
                  </span>
                  {' '}
                  &nbsp;
                  <CopyToClipboard
                    text="Carrot Innovation LLC"
                    onCopy={this.onCopy}
                  >
                    <FaRegCopy style={{ cursor: 'pointer' }} />
                  </CopyToClipboard>
                </div>

                <div className="details-content">
                  <p>Beneficiary account number</p>
                  <span>
                    164040951944184
                  </span>
                  {' '}
                  &nbsp;
                  <CopyToClipboard
                    text="164040951944184"
                    onCopy={this.onCopy}
                  >
                    <FaRegCopy style={{ cursor: 'pointer' }} />
                  </CopyToClipboard>
                </div>

                <div className="details-content">
                  <p>Beneficiary address</p>
                  <span>
                    9802 Woodyard Circle, Upper Marlboro, MD 20772, United States
                  </span>
                  {' '}
                  &nbsp;
                  <CopyToClipboard
                    text="9802 Woodyard Circle, Upper Marlboro, MD 20772, United States"
                    onCopy={this.onCopy}
                  >
                    <FaRegCopy style={{ cursor: 'pointer' }} />
                  </CopyToClipboard>
                </div>

                <div className="details-content car-green">
                  <span className="title">
                    Step 4: Enter Memo/Notes:
                  </span>
                </div>

                <div className="details-content">
                  <p>Memo/Notes</p>
                  <span>
                    FFC Carrot Innovation LLC, Account number 164040951944184, payment for (enter your full name here)
                  </span>
                  {' '}
                  &nbsp;
                  <CopyToClipboard
                    text="FFC Carrot Innovation LLC, Account number 164040951944184, payment for (enter your full name here)"
                    onCopy={this.onCopy}
                  >
                    <FaRegCopy style={{ cursor: 'pointer' }} />
                  </CopyToClipboard>
                </div>

                {' '}
                &nbsp;

                <CopyToClipboard
                  text="
                  CLNOUS66BRX
                  Column NA - Brex
                  1 Letterman Drive, Building A, Suite A4-700, San Francisco, CA, 94129, United States
                  CHASUS33
                  JPMorgan Chase Bank N.A.
                  383 Madison Avenue, New York, NY 10179, United States
                  Carrot Innovation LLC
                  164040951944184
                  9802 Woodyard Circle, Upper Marlboro, MD 20772, United States
                  FFC Carrot Innovation LLC, Account number 164040951944184, payment for (enter your full name here)
                  "
                  onCopy={this.onCopy}
                >
                  <button className="copyButton">COPY ALL</button>
                </CopyToClipboard>
                <br />

                <div className="details-content note">
                  <p>Note:</p>
                  <span>
                    If your bank’s online payment portal doesn’t have a memo/notes field,
                    contact your bank directly to determine how to include a memo/note.
                  </span>
                </div>
                {/* <br /> */}
              </div>
            )}

            {repayMethod === 'naira' && (
              <div className="details-background">
                <div className="details-content">
                  <p>
                    Naira Debit Card, Pay with Bank, USSD, Pay with Transfer,
                    VISA QR
                  </p>
                  <span className="title">
                    1.5% processing fee capped at 2000 NGN
                  </span>
                </div>

                <div className="details-content wider">
                  <p>Note:</p>
                  <span>
                    The processing fee is not a Carrot charge but a fee from our
                    payment processor - Paystack.
                  </span>
                </div>
              </div>
            )}

            <div className="disburseOptions-button">
              <Button
                onClick={
                  repayMethod === 'dollar'
                    ? this.goTo
                    : repayMethod === 'naira'
                      ? this.show('choice', 'open', true)
                      : () => {}
                }
                size="large"
                background="light"
                disabled={repayMethod === ''}
              >
                {repayMethod === 'dollar' ? 'GO TO DASHBOARD' : 'CONTINUE'}
              </Button>
            </div>
          </Page>
        )}

        {open && (
          <PaystackConsumer
            metadata={{
              action: 'repayment',
              id: sha256(`${user_id}`),
              loan_id: loanID,
              rate,
              user_id,
            }}
            publicKey={info.publicKey}
            amount={parseInt(
              Helpers.math.multiply(
                Helpers.math.multiply(payStackAmount, rate),
                100
              ),
              10
            )}
            email={
              process.env.REACT_APP_NODE_ENV === 'development'
                ? 'tech@carrotcredit.com'
                : email
            }
            channels={['card', 'bank', 'mobile_money', 'qr', 'ussd']}
            text="Carrot-Repayment-Paystack"
            onSuccess={this.success}
            onClose={this.show('choice', 'open', false)}
          >
            {({ initializePayment }) => <>{initializePayment()}</>}
          </PaystackConsumer>
        )}
      </>
    );
  }
}

export default Repayment;
