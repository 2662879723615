import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import {
  Button, Page, Input, Successful
} from '../../components';

import * as Helpers from '../../helpers';
import dollarSign from '../../assets/images/dollar-sign.png';
import nairaSign from '../../assets/images/naira-sign.png';
import tickCircle from '../../assets/images/tick-circle.svg';
import infoImg from '../../assets/images/flash-info.svg';

class PaystackWithdrawal extends React.Component {
  state = {
    accountList: [],
    details: {},
    name: '',
    processor_id: 0,
    savedBeneficiary: false,
    savedBeneficiaryForm: false,
    selectedAccount: -1,
    sendToAccount: 'old',
    showAccountDropdown: false,
    showBeneficiaryList: false,
    success: false,
    successMessage: '',
    verified: false,
  };

  componentDidUpdate(_, prevState) {
    const { details, processor_id, sendToAccount } = this.state;

    if (sendToAccount === 'old') return;

    if (JSON.stringify(details) !== JSON.stringify(prevState.details)) {
      const { verifyWithdrawalDetails } = this.props;

      // TODO: Whether or not account details for a
      // processor should be verified should be a
      // processor-level config perhaps called `verify`
      if (processor_id === 3) {
        this.setState({ verified: true });
      } else if (this.validate()) {
        verifyWithdrawalDetails(details, processor_id, response => {
          const { data: { account_name } } = response.data;
          this.setState({ name: account_name, verified: true });
        });
      }
    }
  }

  change = slug => e => {
    const { details, processor_id } = this.state;
    this.setState({
      details: processor_id === 1 && slug === 'account_number'
        ? { ...details, [slug]: e.target.value.substring(0, 10) }
        : { ...details, [slug]: e.target.value },
      verified: false,
    });
  };

  withdraw = () => {
    const {
      details, verified, processor_id, sendToAccount, savedBeneficiary,
    } = this.state;
    const {
      amount, withdraw, saveAccounts,
    } = this.props;

    if (sendToAccount !== 'old' && !this.validate()) {
      return Helpers.notification.error(
        'The bank details you entered are incorrect.'
      );
    }

    if (!verified) {
      return Helpers.notification.error(
        'We could not verify the details you input. Try again.'
      );
    }

    // save account here
    if (savedBeneficiary) {
      saveAccounts(
        details,
        processor_id,
        () => {},
        () => {}
      );
    }

    return withdraw(
      amount,
      details,
      processor_id,
      Helpers.token.get('user:application_id'),
      res => {
        this.setState({
          success: true,
          successMessage: res.message,
        });
      }
    );
  }

  validate = () => {
    const { details, processor_id } = this.state;
    const { withdrawal: { processors } } = this.props;
    let invalidParam = '';
    processors.map(processor => {
      if (processor.processor_id === `${processor_id}`) {
        processor.requirements.every(requiredParam => {
          if (!details[requiredParam.slug] || typeof details[requiredParam.slug] === 'undefined') {
            invalidParam = requiredParam.slug;
            return false;
          }

          if (
            requiredParam.type === 'number' && (!/^\d+$/.test(details[requiredParam.slug])
            || details[requiredParam.slug].length < 10)) {
            invalidParam = requiredParam.slug;
            return false;
          }

          return true;
        });
      }

      return null;
    });

    return !invalidParam;
  }

  sendToAccount = e => {
    const { withdrawal: { accounts } } = this.props;
    const sendToAccount = e.target.value;

    if (sendToAccount === 'old') {
      this.setState({
        accountList: accounts,
        details: {},
        name: '',
        savedBeneficiaryForm: false,
        showBeneficiaryList: true,
        verified: false,
      });
    } else {
      this.setState({
        accountList: [],
        details: {},
        name: '',
        savedBeneficiaryForm: true,
        showBeneficiaryList: false,
        verified: false,
      });
    }

    this.setState({
      sendToAccount,
      verified: false,
    });
  }

  getImage = slug => {
    switch (slug) {
    case 'naira-bank':
      return nairaSign;
    case 'auto-dollar-bank':
      return dollarSign;
    case 'manual-dollar-bank':
      return dollarSign;
    default:
      return '';
    }
  }

  setProcessor = slug => {
    this.setState({ showAccountDropdown: true });

    switch (slug) {
    case 'naira-bank':
      this.setState({ processor_id: 1 });
      break;
    case 'auto-dollar-bank':
      this.setState({ processor_id: 3 });
      break;
    case 'manual-dollar-bank':
      this.setState({ processor_id: 3 });
      break;
    default:
      this.setState({ processor_id: 0 });
    }
  }

  handleSavedBeneficiary = e => {
    const { checked } = e.target;
    this.setState({ savedBeneficiary: checked });
  }

  back = () => {
    const { back } = this.props;
    back();
  }

  render() {
    const {
      details,
      sendToAccount,
      verified, name, success,
      processor_id,
      successMessage,
      accountList,
      savedBeneficiary,
      savedBeneficiaryForm,
      showBeneficiaryList,
      showAccountDropdown,
      selectedAccount,
    } = this.state;
    const {
      user: { loading }, withdrawal: { processors }, amount, history,
    } = this.props;

    if (success) {
      return (
        <Successful
          title="Withdrawal"
          subtitle={successMessage || `We have begun the process of transferring $${Helpers.math.divide(amount, 100)} to your bank account provided`}
          action="Go to Dashboard"
          background="illustration-3"
          history={history}
        />
      );
    }
    return (
      <Page
        title="Withdraw"
        alignContentMobile="align-left"
        back={this.back}
        background="illustration-3"
        hasHeader
        hasLogo
        hm="20px 0 0 0"
        lm="39.2px 0 0 0"
        footer={<></>}
      >
        <>
          <div className="heading disburseOptions">
            Where would you like your money sent to?
          </div>

          <div className="subheading disburseOptions">
            Please choose from one of our local or international disbursement options.
          </div>

          <div className="disburse-options">
            {
                processors.map((processor, i) => processor.slug !== 'auto-dollar-bank' && (
                  <button key={i} className="item highlight" onClick={() => this.setProcessor(processor.slug)}>
                    <img src={this.getImage(processor.slug)} alt="" className="currency-image" />
                    {processor.display_name}
                    <img className="tick" src={tickCircle} alt="" />
                  </button>
                ))
              }
          </div>

          {showAccountDropdown && (
          <div className="disburseOptions-input">
            <Input
              placeholder="Select Account"
              type="select"
              onChange={this.sendToAccount}
              value={sendToAccount}
              options={[{
                label: 'Select from existing list of beneficiaries',
                value: 'old',
              }, {
                label: 'Add New Account',
                value: 'new',
              }]}
              disabled={processor_id === 0}
            />
          </div>
          )}

          {showBeneficiaryList && (
          <div>
            {accountList.length > 0 ? (
              accountList.map((account, i) => (
                <div className="disburse-options" key={i}>
                  <button
                    className={`item highlight ${selectedAccount === i ? 'account-options-active' : ''}`}
                    onClick={() => this.setState({
                      details: {
                        account_number: account.account_number,
                        bank_code: account.bank_code,
                      },
                      name: account.account_name,
                      selectedAccount: i,
                      verified: true,
                    })}
                  >
                    <img
                      src={this.getImage(account.slug || 'naira-bank')}
                      alt=""
                      className="currency-image"
                    />
                    <div className="account-options-button-content">
                      {account.bank_name}
                      {' '}
                      {`${'*'.repeat(account.account_number.length - 4)}${account.account_number.slice(-4)}`}
                      {' -'}
                      {account.account_name}
                    </div>
                    <img className="tick" src={tickCircle} alt="" />
                  </button>
                </div>
              ))
            ) : (
              <div className="no-saved-accounts">
                <img
                  src={infoImg}
                  alt="info"
                />
                <p>No saved accounts found.</p>
              </div>
            )}
          </div>
          )}

          {savedBeneficiaryForm && processors.length > 0 && (
            <>
                {
                  processors
                    .filter(processor => processor.processor_id === `${processor_id}`)
                    .flatMap((processor, i) => processor.requirements.map((requirement, j) => (
                      <div key={`${i}-${j}`} className="disburseOptions-input">
                        <Input
                          placeholder={requirement.placeholder}
                          type={requirement.type}
                          onChange={this.change(requirement.slug)}
                          value={details[requirement.slug] || ''}
                          options={
                              requirement.options?.map(option => ({
                                label: option.name,
                                value: option.code,
                              }))
                            }
                        />
                      </div>
                    )))
                }
            </>
          )}

          { processor_id === 3
              && sendToAccount === 'new'
                && processors.map((processor, i) => processor.processor_id === `${processor_id}`
                  && processor.requirements.map((requirement, j) => (
                    <div key={`${i}//${j}`} className="disburseOptions-input">
                      <Input
                        key={`${i}//${j}`}
                        placeholder={requirement.placeholder}
                        type={requirement.type}
                        onChange={this.change(requirement.slug)}
                        value={details[requirement.slug] || ''}
                        options={requirement.options && requirement.options.map(option => ({ label: option.name, value: option.code }))}
                      />
                    </div>
                  )))}

          {(verified && processor_id === 1 && !showBeneficiaryList) && (
          <div className="disburseOptions-input">
            <Input onChange={() => {}} placeholder="Account Name" value={name} />
            <FormGroup>
              <FormControlLabel
                control={(
                  <Checkbox
                    color="default"
                    checked={savedBeneficiary}
                    onChange={this.handleSavedBeneficiary}
                    inputProps={{ 'aria-label': 'controlled' }}
                    sx={{
                      '&.Mui-checked': {
                        color: '#00ab84',
                      },
                    }}
                  />
                      )}
                label={<p className="remember-me-label">Save Account</p>}
                sx={{
                  color: '#353535',
                  justifyContent: 'left',
                  m: 2,
                  ml: '-12px',
                  opacity: 0.9,
                  p: 0,
                }}
              />
            </FormGroup>
          </div>
          )}

          <div className="disburseOptions-button">
            <Button
              loading={loading.some(url => url === '/withdrawal')}
              disabled={!verified}
              onClick={this.withdraw}
              size="large"
              background="light"
            >
              NEXT
            </Button>
          </div>
        </>
      </Page>
    );
  }
}

export default PaystackWithdrawal;
