import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import ChangeCarrotLogo from './ChangeCarrotLogo';

import whiteLogoMark from '../assets/images/carrot-logo-mark-white.png';
import whiteLogoMarkXmas from '../assets/images/carrot-logo-mark-white-xmas.png'; // xmas

import cancel from '../assets/images/cancel.svg';
import 'animate.css';

class Hamburger extends Component {
  render() {
    const {
      menu, toggleMenu, firstname, out,
    } = this.props;
    return (
      <>
        {menu && (
        <div id="hamburger-parent" className="animate__animated animate__slideInDown animate__delay-50s">
          <div id="hamburger-name-header" onClick={toggleMenu}>
            <img src={cancel} alt="" />
          </div>
          <div id="hamburger-logo-welcome-name">
            <div id="hamburger-carrot-logo-mark">
              <ChangeCarrotLogo
                imageXmas={<img src={whiteLogoMarkXmas} alt="logo mark" />}
                image={<img src={whiteLogoMark} alt="logo mark" />}
              />
            </div>
            <div>
              <div id="hamburger-welcome">
                Welcome,
              </div>
              <div id="hamburger-user-name">
                {firstname || ''}
                <span role="img" aria-label="waving emoji">👋</span>
              </div>
            </div>
          </div>
          <div id="hamburger-body">
            {(window.location.pathname !== '/dashboard') && (
              <>
                <NavLink style={{ textDecoration: 'none' }} to="/dashboard">
                  <div className="item">
                    Dashboard
                  </div>
                </NavLink>
                <div className="horizontal-divider" />
              </>
            )}
            <NavLink style={{ textDecoration: 'none' }} to="/profile">
              <div className="item">
                My Profile
              </div>
            </NavLink>
            <div className="horizontal-divider" />
            <NavLink style={{ textDecoration: 'none' }} to="/forgot">
              <div className="item">
                Forgot Password
              </div>
            </NavLink>
            <div className="horizontal-divider" />
            {(window.location.pathname !== '/faqs') && (
              <>
                <NavLink style={{ textDecoration: 'none' }} to="/faqs">
                  <div className="item">
                    FAQs
                  </div>
                </NavLink>
                <div className="horizontal-divider" />
              </>
            )}
            {(window.location.pathname === '/faqs') && (
              <>
                <NavLink style={{ textDecoration: 'none' }} to="/faqs">
                  <div className="item">
                    Get started
                  </div>
                </NavLink>
                <div className="horizontal-divider" />
              </>
            )}
            {(window.location.pathname !== '/faqs') && (
              <>
                <NavLink style={{ color: '#FE5000', textDecoration: 'none' }} to="/signin" onClick={out}>
                  <div className="item">
                    Log Out
                  </div>
                </NavLink>
                <div className="horizontal-divider" />
              </>
            )}
          </div>
        </div>
        )}
      </>
    );
  }
}

export default Hamburger;
